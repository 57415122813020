// Import syntax highlighting rules (for <code>).
@import "./highlight.css";

// Set our main colors
$maincolor: #71828a;
$linkcolor: #1e6bb8;

// Update Bulma's global variables
$primary: $maincolor;
$link: $linkcolor;
$link-active: $maincolor;
$body-size: 20px;

$notification-background-color: hsl(60, 100%, 96%);

// Import the rest of Bulma
@import "./assets/styles/bulma/bulma.sass";

// Overwrite some details.
.content h1 {
  color: $maincolor;
  font-weight: bold;
  font-size: 2.5rem;
}

.deltachatbots-logo {
  margin-bottom: 1rem;
}

//fix bottom padding of code blocks
div.highlighter-rouge {
  padding-bottom: 1em;
}

@include mobile {
  .deltachatbots-logo {
    float: left;
  }
  .menu-list {
    text-align: right;
  }
  .content h1 {
    display: inline-block;
  }
  .menu-list a.is-active {
    max-width: -moz-max-content;
    margin-left: auto;
    margin-right: 0;
  }
}

